import { ComponentOptions } from 'vue'
import { http } from '../plugins/http'

export default <ComponentOptions>{
  data: () => {
    return {
      feedbackMessage: '',
    }
  },
  methods: {
    showFeedbackModal() {
      this.$modal.open('app-feedback')
    },
    submitFeedback(event: MouseEvent) {
      const target = event.target as HTMLFormElement
      const message = this.feedbackMessage

      if (message.length < 1) {
        return
      }

      http
        .post(target.action, {
          message,
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          screen: {
            width: window.screen.width,
            height: window.screen.height,
          },
        })
        .then((response) => {
          const data = response.data

          this.$flits.success(data.message)
          this.$modal.close('app-feedback')

          this.$nextTick(() => {
            this.feedbackMessage = ''
          })
        })
    },
  },
}
