import { App } from 'vue'
import { i18n } from '/@admin/plugins/i18n'
import modal from '/@admin/components/v-modal'
import flits from '/@admin/plugins/flits'

export const registerPlugins = (app: App) => {
  app.use(modal)
  app.use(i18n)
  app.use(flits)
}
