import messages from '/@admin/locale/en.json'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'en',
  messages: { en: messages },
})

export default i18n

export { i18n }
