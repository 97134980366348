import ModalComponent from './script'
import { Vue } from 'vue-class-component'
import { App } from 'vue'

export interface ModalInterface {
  register(modal: Vue): void
  unregister(modal: Vue): void
  open(name: string): void
  close(name: string): void
  toggle(name: string): void
  closeModal(name: string): void
}

class ModalManager {
  private modals = new Map<string, ModalComponent>()

  register(modal: ModalComponent) {
    const name = modal.name
    if (this.getModal(name)) {
      throw new Error(`Modal names must be unique, name: "${name}" has already been registered.`)
    }

    this.modals.set(name, modal)
  }

  unregister(modal: ModalComponent) {
    this.modals.delete(modal.name)
  }

  open(name: string) {
    this.getModal(name).showHandler(true)
  }

  close(name: string) {
    this.getModal(name).showHandler(false)
  }

  toggle(name: string) {
    const modal = this.getModal(name)
    modal.showHandler(!modal.show)
  }

  closeModal(name: string) {
    this.getModal(name).closeModal()
  }

  getModal(name: string): ModalComponent {
    return this.modals.get(name) as ModalComponent
  }
}

export default {
  install(app: App, plugin = '$modal') {
    const manager = new ModalManager()

    app.config.globalProperties[plugin] = {
      register(modal: ModalComponent) {
        manager.register(modal)
      },
      unregister(modal: ModalComponent) {
        manager.unregister(modal)
      },
      open(name: string) {
        manager.open(name)
      },
      close(name: string) {
        manager.close(name)
      },
      toggle(name: string) {
        manager.toggle(name)
      },
      closeModal(name: string) {
        manager.closeModal(name)
      },
    }
  },
}
